<template>
  <div>
    <small>
      RPA 자동발주를 할 때 사용하는 엑셀 파일 입니다. 누르면 엑셀이 다운 받아지고 배송준비로 바뀝니다.
    </small>
    <b-card>
      <h4>RPA 용 주문 Down &amp; 배송준비 상태로 변경하기</h4>
      <br/>
      <b-col lg="3">
        <strong>주문 다운 받을 샵 ID와 샵 이름을 입력</strong><br/>
        <b-form-input id="shop" class="mt-2" type="text" placeholder="주문을 다운 받을 샵 ID와 샵 이름을 입력하세요 (예: 46|OKMALL)" v-model="form.shop_id"></b-form-input>
      </b-col>
      <b-form inline>
        <b-button class="m-3" variant="success" @click="placeOrderDown('xlsx')">다운</b-button>
      </b-form>
    </b-card>
    <small>
      RPA 환불 할 때 사용하는 엑셀 파일 입니다. 누르면 엑셀이 다운 받아집니다.
    </small>
    <b-card>
      <h4>RPA 용 환불 주문 Down &amp; 주문 상태 변경하기</h4>
      <br/>
      <b-row>
        <b-col lg="3" class="ml-3">
          <strong>주문 상태 지정</strong><br/>
          <b-button class="mt-1 mb-2" size="sm" variant="primary" @click="toggleStatus">전체 선택</b-button>
          <b-form-checkbox-group name="statusMulti" v-model="form.statusMulti">
            <b-form-checkbox v-for="s in order_status_code" :key="s.code" :value="s.code">{{s.name}}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
        <b-col lg="3">
          <strong>MALL 지정</strong><br/>
          <b-button class="mt-1 mb-2" size="sm" variant="primary" @click="toggleMall">전체 선택</b-button>
          <b-form-checkbox-group name="mallMulti" v-model="form.mallMulti">
            <b-form-checkbox v-for="s in mall" :key="s.name" :value="s.name">{{s.name}}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <br/>
      <b-col lg="3">
        <small>한 번에 가져올 상품 수</small><br/>
        <b-form-input id="limit" type="text" placeholder="한 번에 가져올 상품 수" class="mt-2" v-model.number="form.limit"></b-form-input>
      </b-col>
      <br/>
      <b-form inline>
        <b-button class="ml-3" variant="success" @click="refundDown('xlsx')">환불</b-button>
      </b-form>
    </b-card>
    <small>
      RPA 용 엑셀 업로드입니다.
    </small>
    <b-card>
      <h4>RPA 용 주문 업데이트</h4>
      <br/>
      <div>
        <b-row class="mb-3">
          <b-col>
            <div id="dropUpdate" class="drop" data-type="update" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover" @click="_=>{$refs.xlsxUpdate.value = null;$refs.xlsxUpdate.click()}">
              Update File Drop or Click
            </div>
            <input type="file" ref="xlsxUpdate" data-type="update" style="display: none" @change="handleFile">
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<style>
.drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 50px;
  text-align: center;
  font: 20pt bold,"Vollkorn";
  color: #bbb;
  cursor: pointer;
}
</style>

<script>
import {down, readXlsx} from '@/shared/impexp';
export default {
  name: 'RpaOrder',
  title: 'RPA 주문',
  components: {},
  data() {
    return {
      items: {list:[]},
      order_status_code: [
        {code: 'withdraw', name: '품절'},
        {code: 'cancel_approve', name: '취소승인'},
        {code: 'refund_approve', name: '반품승인'},
      ],
      mall:   [
        {name: '고도몰', id: 'godo'},
        {name: '스토어팜 (발란)', id: 'smartstore'},
      ],
      form: {
        statusMulti: [],
        mallMulti: [],
        shop_id: '',
        limit: 100,
      },
    }
  },
  async created() {
    // this.form.statusMulti = this.order_status_code.map(e=>e.code);
    this.form.mallMulti = this.mall.map(e=>e.name);
  },
  methods: {
    setStatusMulti(statusStr) {
      this.form.statusMulti = statusStr.split(',');
    },
    setMallMulti(statusStr) {
      this.form.mallMulti = statusStr.split(',');
    },
    toggleStatus() {
      this.form.statusMulti = this.form.statusMulti.length === this.order_status_code.length ? [] : this.order_status_code.map(e=>e.code);
    },
    toggleMall() {
      this.form.mallMulti = this.form.mallMulti.length === this.mall.length ? [] : this.mall.map(e=>e.name);
    },
    async placeOrderDown(type) {
      if (!this.form.shop_id) return alert('샵 ID를 입력해야합니다.');
      let j = await this.$api.getJson(`/order/getOrderList?shop_id=${this.form.shop_id}`);
      if (j) {
        let selectedItems = j.orders;
        this.items.list = selectedItems;
        selectedItems.forEach(e=>e._ps_log = e.ps_log ? e.ps_log.filter(e=>!e._del_dt).map(e=>`${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
        selectedItems.forEach(e=>e._op_log = e.op_log ? e.op_log.filter(e=>!e._del_dt).map(e=>`${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
        if (!selectedItems.length) return alert('주문이 없습니다.');
        let name = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.name).concat(['PS Log', 'OP Log', '통화', '상품원가']);
        let code = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.code).concat(['_ps_log', '_op_log', 'price_table.currency', 'price_table.supply.ivaPrice']);
        down(selectedItems, name, code, `DeliverBoard_${this.$utils.dt()}`, type);
      }
    },
    async refundDown(type) {
      // 주문을 먼저 취소확인, 반품확인으로 변경하면 재고 문제 발생 가능성있음
      if (!this.form.mallMulti.length) {
        return alert('MALL을 선택해주세요.');
      }
      if (!this.form.statusMulti.length) {
        return alert('주문 상태를 선택해주세요.');
      }
      const body = {order_status_code: {$in: this.form.statusMulti}, mall: this.form.mallMulti, limit: this.form.limit};
      let j = await this.$api.postJson(`/order/refundOrderList`, body);
      if (j) {
        const selectedItems = j.orders;
        this.items.list = selectedItems;
        selectedItems.forEach(e=> {
          if (!e.mall_info) {
            e.settlekind = '';
          } else if (e.mall === '스토어팜 (발란)') {
            e.settlekind = e.mall_info.PaymentMeans;
            e.pg = '';
          } else if (e.mall === '고도몰' && e.mall_info) {
            let goods_ratio = e.sales_price / (e.mall_info.settleprice + e.mall_info.emoney + e.mall_info.coupon); // sales_price = settleprice + coupon + emoney
            e.emoney_divided = this.$utils.round(goods_ratio * e.mall_info.emoney);
            e.settlekind = (this.$C.SETTLE_KIND_GODO[e.mall_info.settlekind] || {}).name || '';
            e.pg = e.mall_info.pg || 'inipay';
          }
          e._ps_log = e.ps_log ? e.ps_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : ''
        });

        if (!selectedItems.length) return alert('주문이 없습니다.');
        let name = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.name).concat(['PS Log', 'OP Log', '통화', '상품원가', 'pg', '결제수단', '적립금 사용액']);
        let code = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.code).concat([
          '_ps_log', '_op_log', 'price_table.currency', 'price_table.supply.ivaPrice', 'pg', 'settlekind', 'emoney_divided'
        ]);
        down(selectedItems, name, code, `DeliverBoard_${this.$utils.dt()}`, type);
      } else {
        return alert(`환불 주문 다운 도중 오류 발생했습니다.`);
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      if (this.$store.state.user.id !== 'RPA_Robot001') {
        return this.$utils.alert('RPA 계정만 사용할 수 있는 업로드 기능입니다.');
      }
      let file = (event.dataTransfer || event.target).files[0];
      let typeEl = event.target;
      while (!typeEl.dataset.type && typeEl !== document.body) {
        typeEl = typeEl.parentElement;
      }
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file);
      this.uploadXlsxData(event.target, headers, rows);
    },
    async uploadXlsxData(target, headers, rows) {
      // 업로드 전 내용을 체크한다.
      // 1. 컬럼들이 올바른가
      // 2. 컬럼들 데이터 형식이 올바른가
      // 3. 상태변경이 올바른가 (서버)

      let colNameMap = {};
      this.$C.DELIVER_BOARD_COLUMNS.forEach(e=>{
        colNameMap[e.name] = e;
      });

      let required = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.required).map(e=>e.name).filter(e=>!~headers.indexOf(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n'+required.join('\n'));

      let wrongRows = [];
      const unknown = [];
      rows.forEach((e, i)=>{
        let wrongCols = [];
        headers.forEach(h=>{
          if (!colNameMap[h]) {
            delete e[h];
            unknown.push(h);
            return;
          }
          let tester = colNameMap[h].test;

          if (e[h] != null && e[h] !== '' && colNameMap[h].upload && tester && !tester.test(e[h])) {
            wrongCols.push(`${h}: ${e[h]}`);
          }
          if ((e[h] == null || e[h] === '') && colNameMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx:i, cols:wrongCols});
      });
      headers = headers.filter(h => !unknown.includes(h));
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n'+wrongRows.map(e=>`${e.idx+2} 번째줄 ${e.cols.map(e=>e).join(', ')}`).join('\n'));

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      let typeHeaders = headers.filter(h=>colNameMap[h].type);
      rows.forEach(row=>{
        typeHeaders.forEach(h=>{
          let type = colNameMap[h].type;
          if (type === 'number') {
            row[h] = +row[h];
          } else if (type === 'string') {
            row[h] = row[h] == null ? null : '' + row[h];
          }
        });
      });

      let j = await this.$api.postJson('/order/uploadBulk', {type:target.dataset.type, items:rows});
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt}건 업로드 되었습니다.${j.issueOrders && j.issueOrders.length ? `\n${j.issueOrders.map(e=>`${e.oid}: ${e.msg}`).join('\n')}` : ''}`);
        this.list();
      } else if (j.ok === -1) {
        this.modal.ok = true;
        this.okModalTitle = '업로드 에러 확인';
        this.okModalHtml = '<pre>' + `<h4>${j.msg}</h4>` + j.errors.map(e=>`<span class="badge badge-light">${e.orderno}:${e.order_detailno}</span> - ${e.error||''}`).join('<br/>') + '</pre>';
      }
      target.value = "";
    },
  }
}
</script>
